import { Entity, Scene } from 'aframe-react'
import React from 'react'
import _ from 'lodash'
import { config } from '~/utilsjs'

import enhancer from '~/redux/allEnhancer'
import PanoramaController from './panorama'

class Panorama extends PanoramaController {
  render() {
    if (!this.props.auth.id) return <span></span>
    const { panorama } = this.props
    const { displayType } = this.state
    const pano = panorama.panorama
    const panos = panorama.panoramas
    const panoIndex = _.findIndex(panos, pa => pa.text == pano.text)
    
    return (
      <div className="panorama">
        {panoIndex != -1 && panoIndex+1 < panos.length &&
          <img style={{visibility: 'hidden'}} src={config.urls.api.https+panos[panoIndex+1].image_url}></img>
        }
        {0 < panoIndex &&
          <img style={{visibility: 'hidden'}} src={config.urls.api.https+panos[panoIndex-1].image_url}></img>
        }
        <Scene id="scene" device-orientation-permission-ui="enabled: false">
          <Entity primitive="a-sky" src={pano.image_url}/>
          <Entity primitive="a-camera" look-controls zoom={8} orbit-controls="target: 0 1.6 -0.5; initialPosition: 0 5 2600; rotateSpeed: -0.26; zoomSpeed: 2; minDistance: 200; maxDistance: 3200; maxPolarAngle: 180; enableDamping: false;" />
        </Scene>
        {panoIndex != -1 && panoIndex+1 < panos.length && displayType == 0 &&
          <div className="next" onClick={() => this.move(panoIndex+1)}><i className="fa fa-angle-right"/></div>
        }
        {0 < panoIndex && displayType == 0 &&
          <div className="prev" onClick={() => this.move(panoIndex-1)}><i className="fa fa-angle-left"/></div>
        }
        {displayType != 2 &&
          <div className="id">{pano.text}</div>
        }
      </div>
    )
  }
}

export default enhancer(Panorama)
