import { Component } from 'react'

export default class PanoramaController extends Component {
  constructor() {
    super()
    this.state = {displayType: 0}
  }
  componentDidMount() {
    if (!this.props.auth.id) return
    const { actions, match } = this.props
    actions.loadPanoramas()

    const scene = document.getElementsByClassName('panorama')[0]
    const footer = document.getElementsByClassName('footer-contents')[0]
    let tapCount = 0
    scene.addEventListener('touchstart', () => {
      if (!tapCount) {
        ++tapCount
        setTimeout( function() {
          tapCount = 0
        }, 350)
      } else {
        const nextDisplayType = (this.state.displayType+1)%3
        this.setState({displayType: nextDisplayType})
        if (nextDisplayType == 0) {
          footer.style.display = 'flex'
        } else {
          footer.style.display = 'none'
        }
        tapCount = 0
      }
    })
  }
  move(index) {
    const { actions, panorama, history } = this.props
    const pano = panorama.panoramas[index]
    actions.setPanorama({id: pano.id, text: pano.text, image_url: pano.image_url})
    history.push('/panorama/'+pano.id)
  }
}