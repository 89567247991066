import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import enhancer from '~/redux/allEnhancer'

class Footer extends Component {
  render() {
    const { auth, location } = this.props
    const { history } = this.props
    const pathNames = history.location.pathname.split('/')
    let panoramaId = null
    if (pathNames[1] === 'panorama') {
      panoramaId = pathNames[2]
    }
    return (
      <footer>
        {auth.token && location.pathname !== '/' &&
          <div className="footer-contents">
            {panoramaId && <Link to="/" className=""><i className="fas fa-home"></i></Link>}
            {panoramaId && <Link to={"/"+panoramaId} className=""><i className="fas fa-long-arrow-alt-left"></i></Link>}
          </div>
        }
      </footer>
    )
  }
}

export default enhancer(Footer)
