import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import enhancer from '~/redux/allEnhancer'
import HeaderController from './header'

class Header extends HeaderController {
  render() {
    const { auth, location } = this.props
    return (
      <header>
        {auth.token && location.pathname === '/' &&
          <div className="header-contents">
            <Link to="/signout" className=""><i className="fas fa-sign-out-alt"></i></Link>
          </div>
        }
      </header>
    )
  }
}

export default enhancer(Header)
