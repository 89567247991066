import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import Header from './Header.jsx'
import Footer from './Footer.jsx'
import Home from '../home/Home.jsx'
import SignIn from '../auth/SignIn.jsx'
import SignOut from '../auth/SignOut.jsx'
import AerialMap from '../aerialMap/AerialMap.jsx'
import AerialMapZoom from '../aerialMap/AeriaMapZoom';
import Panorama from '../panorama/Panorama.jsx'

import AppController from './app'

class App extends AppController {
  render() {
    return (
      <Router>
        <div>
          <Header />
          <Switch>
            <Route exact path='/' render={() => <AerialMap />} />
            <Route exact path='/home' render={() => <Home />} />
            <Route path='/signin' render={() => <SignIn />} />
            <Route path='/signout' render={() => <SignOut />} />
            <Route path='/panorama/:id' render={() => <Panorama />} />
            <Route exact path='/:id' render={() => <AerialMapZoom />} />
          </Switch>
          <Footer />
        </div>
      </Router>
    )
  }
}

export default App
